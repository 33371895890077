import React from 'react'

import './index.scss'

import classnames from 'classnames'

export function Panel({ children, hideBackground = false, className = '' }) {
	const classes = classnames({
		'panel-container': true,
		'panel-no-background': hideBackground,
		[className]: !!className
	})
	return <section className={classes}>{children}</section>
}

export function PanelHeader({ children, noBorder = false, center = false, handleClick = () => {} }) {
	const classes = classnames({
		'panel-header': true,
		'panel-header-border': !noBorder,
		'panel-center-contents': center
	})
	return (
		<div className={classes} onClick={handleClick}>
			{children}
		</div>
	)
}

export function PanelTitle({ children, subtitle = undefined }) {
	return (
		<>
			<h2 className="panel-header-title">
				{children}
				{subtitle && (
					<span
						style={{
							fontSize: '.8rem',
							marginLeft: 7,
							letterSpacing: '.1rem',
							fontWeight: 300
						}}
					>
						{subtitle}
					</span>
				)}
			</h2>
		</>
	)
}

export function PanelTitleButton({ children }) {
	return <div className="title-button">{children}</div>
}

export function PanelBody({ children, className = undefined }) {
	const classes = classnames({
		'panel-body': true,
		[className]: !!className
	})
	return <div className={classes}>{children}</div>
}

export function PanelControls({ children }) {
	return <div className="panel-controls">{children}</div>
}

export function PanelRow({ children }) {
	return <div className="panel-row">{children}</div>
}
