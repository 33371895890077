import React from 'react'

import './popper-overlay.scss'

import { useCallback, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from '@chakra-ui/popper'
import classNames from 'classnames'

// Define default options if needed
const defaultOptions = { placement: 'bottom-end' }

export function usePopperOverlay(props) {
	const { buttonText, buttonClassName, options = defaultOptions, popperClassName } = props

	const [isOpen, setIsOpen] = useState(false)

	const { popperRef, referenceRef } = usePopper(options)

	const closePopperOverlay = useCallback(() => {
		setIsOpen(false)
	}, [])

	const openPopperOverlay = useCallback(() => {
		setIsOpen(true)
	}, [])

	const togglePopperOverlay = useCallback(() => {
		isOpen ? closePopperOverlay() : openPopperOverlay()
	}, [isOpen, closePopperOverlay, openPopperOverlay])

	const PopperOverlay = ({ children }) => (
		<OutsideClickHandler onOutsideClick={closePopperOverlay}>
			<button ref={referenceRef} className={classNames('popper-overlay-button', buttonClassName)} onClick={togglePopperOverlay}>
				{buttonText}
			</button>

			<div
				ref={popperRef}
				className={classNames('popper-tool-tip', popperClassName, {
					'popper-tool-tip-hidden': !isOpen
				})}
			>
				{children}
			</div>
		</OutsideClickHandler>
	)

	return {
		PopperOverlay,
		closePopperOverlay,
		togglePopperOverlay
	}
}
