import React from 'react'

import './icon-value-display.scss'

import classnames from 'classnames'

export function IconValueDisplay({ icon, value, label }) {
	return (
		<div className="icon-value-display">
			{icon}
			<div>
				<div className="value">{value}</div>
				<div className="label">{label}</div>
			</div>
		</div>
	)
}

export function IconValueDisplayWrap({ children, twoColumns }) {
	const classes = classnames({
		'icon-value-display-wrapper': true,
		'icon-value-display-wrapper-two-columns': twoColumns
	})
	return <div className={classes}>{children}</div>
}
