import React from 'react'

import { Field, ErrorMessage } from 'formik'
import FilterSelect from './FilterSelect'
import MultiValue from './MultiValue'
import './input-group.scss'

// as = input select or textarea

/**
 * Input Group built to work with Formik
 *
 */
function InputGroup({
	type = 'text',
	placeholder = '',
	name = '',
	label = '',
	as = 'input',
	rows = [],
	children = null,
	disabled = false,
	className = '',
	helperText = '',
	...rest
}) {
	let fieldProps = {}
	let component = null
	if (type === 'filter-select') {
		as = null
		component = FilterSelect
		fieldProps = rest
	}
	if (type === 'multi-value') {
		as = null
		component = MultiValue
		fieldProps = rest
	}
	if (type === 'checkbox') {
		return (
			<div className="input-container" data-field-name={name}>
				<label>
					<Field type="checkbox" name={name} disabled={disabled} />
					{label}
				</label>
			</div>
		)
	}
	if (React.Children.count(children) > 0 && as === 'input') {
		as = 'select'
	}
	return (
		<div className={`input-container ${className}`} data-field-name={name}>
			<label className="label">{label}</label>
			<Field
				type={type}
				name={name}
				placeholder={placeholder}
				component={component}
				as={as}
				disabled={disabled}
				rows={rows}
				{...fieldProps}
				{...rest}
			>
				{React.Children.map(children, (option) => option)}
			</Field>
			{helperText && <div className="helper-text">{helperText}</div>}

			<ErrorMessage name={name} className="error" component="div" />
		</div>
	)
}

export default InputGroup
