import React from 'react'

import { useState } from 'react'
import CreatableSelect from 'react-select/creatable'

const targetHeight = 40

const customStyles = {
	container: (base) => ({
		...base,
		fontSize: 18,
		borderRadius: 5,
		minHeight: 'inital',
		fontWeight: 400
	}),
	control: (base) => ({
		...base,
		border: '1px solid #e9e9e9',
		padding: '0 8px',
		borderRadius: 5,
		minHeight: targetHeight,
		borderColor: 'red'
	}),
	valueContainer: (base) => ({
		...base,
		minHeight: targetHeight
	}),
	input: (styles) => ({
		...styles,
		height: 29
	})
}

const createOption = (label) => ({
	label,
	value: label
})

function MultiValue({ name, form, field, ...rest }) {
	const [inputValue, setInputValue] = useState('')

	const setValue = (newValue) => form.setFieldValue(field.name, newValue)

	const handleKeyDown = (event) => {
		if (!inputValue) return
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				if (field.value === inputValue || (Array.isArray(field.value) && field.value.find((x) => x.value === inputValue))) {
					console.log('cant enter same value twice')
					return
				}
				setValue([...field.value, createOption(inputValue)])
				setInputValue('')
				event.preventDefault()
		}
	}

	return (
		<CreatableSelect
			name={name}
			styles={customStyles}
			components={{ DropdownIndicator: null }}
			inputValue={inputValue}
			isClearable
			isMulti
			menuIsOpen={false}
			onChange={(newValue) => setValue(newValue)}
			onInputChange={(newValue) => setInputValue(newValue)}
			onKeyDown={handleKeyDown}
			placeholder="Type something and press enter..."
			value={field.value}
			{...rest}
			type="text"
		/>
	)
}

export default MultiValue
