import React from 'react'

import { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Modal } from 'components/atoms/Modal'

// Create the context
export const ModalContext = createContext(undefined)

export function ModalContextProvider({ children }) {
	const [show, setShow] = useState(false)
	const location = useLocation()
	const [content, setContent] = useState(null)

	useEffect(() => {
		setShow(false)
	}, [location.pathname])

	const handleShow = (title, body) => {
		setContent({ title, body })
		setShow(true)
	}

	const handleHide = () => setShow(false)

	const modalContext = { showModal: handleShow }

	return (
		<ModalContext.Provider value={modalContext}>
			{children}
			{content && (
				<Modal isOpen={show} closeModal={handleHide} contentLabel={content.title} headerLabel={content.title}>
					<content.body handleHide={handleHide} />
				</Modal>
			)}
		</ModalContext.Provider>
	)
}
