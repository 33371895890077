import React from 'react'

import { createContext } from 'react'

import { useAuth } from 'features/auth'
import useUsersContact from 'features/contacts/api/useUsersContact'
import { useAccountId } from 'features/accounts/AccountContext'

const AccountRoleContext = createContext()

function AccountRoleProvider({ children }) {
	const { auth } = useAuth()
	const { accountId } = useAccountId()
	const { data: usersContact } = useUsersContact()

	let accountRole = null
	let userContact = null
	let currentAccountContact = null
	if (auth && !auth.isAdmin) {
		let { accountContacts, ...restOfUserContact } = usersContact
		currentAccountContact = accountContacts.find((ac) => ac.Account__r.Postgres_External_Key__c === accountId)
		accountRole = currentAccountContact ? currentAccountContact.Contact_Role__c : null
		userContact = restOfUserContact
	}

	return (
		<AccountRoleContext.Provider
			value={{
				accountRole,
				isAdmin: auth?.isAdmin,
				currentAccountContact,
				userContact
			}}
		>
			{children}
		</AccountRoleContext.Provider>
	)
}

export { AccountRoleProvider, AccountRoleContext }
