import React from 'react'

import { useLocation, Outlet, Navigate } from 'react-router-dom'

import { useAuth } from 'features/auth'
import { useAccountId } from 'features/accounts'
import { AccountRoleProvider } from 'contexts/AccountRoleContext'
import SuspenseLoading from '../atoms/SuspenseLoading'
import Header from 'components/sections/Header'
import Footer from 'components/sections/Footer'

// https://www.robinwieruch.de/react-router-private-routes/
export const ProtectedRoute = ({ children }) => {
	const { auth } = useAuth()
	const { accountId } = useAccountId()
	const { pathname, search } = useLocation()

	const params = new URLSearchParams(search)
	if (!auth) {
		const redirect = pathname !== '/' ? `?redirectTo=${pathname}` : ''

		if (pathname.split('/')[1] === 'admin') {
			return <Navigate to={`/employee-login${redirect}`} replace />
		}

		return <Navigate to={`/login${redirect}`} replace />
	}

	const redirectTo = params.get('redirectTo')
	if (redirectTo) {
		return <Navigate to={redirectTo} replace />
	}

	const path = pathname.split('/')[1]
	const allowedWithNoAccountSelected = path !== '' && ['account-select', 'admin', 'lead', 'call'].some((x) => x.includes(path))

	if (!accountId && !allowedWithNoAccountSelected) {
		return <Navigate to="/account-select" />
	}

	return (
		<div className="content">
			<Header />

			<main className="main-container">
				<SuspenseLoading>
					<AccountRoleProvider>{children || <Outlet />}</AccountRoleProvider>
				</SuspenseLoading>
			</main>

			<Footer />
		</div>
	)
}

export default ProtectedRoute
