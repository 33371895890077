import React from 'react'

import { useFormikContext } from 'formik'
import './index.scss'

function StatusBanner() {
	let { status, setStatus } = useFormikContext()
	if (!status) {
		return null
	}

	if (status.type === 'success') {
		setTimeout(() => {
			setStatus(null)
		}, 10000)
	}
	return (
		<div className={`banner ${status.type}-banner`} role="alert">
			{status.message}
		</div>
	)
}

export default StatusBanner
