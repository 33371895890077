import React from 'react'

import { useAuth } from 'features/auth'
import { useLocation, Outlet, Navigate } from 'react-router-dom'

export const OpenRoute = ({ children }) => {
	const { auth } = useAuth()
	const { search } = useLocation()

	const params = new URLSearchParams(search)
	const redirectTo = params.get('redirectTo')

	if (auth) {
		const redirect = redirectTo || '/'
		return <Navigate to={redirect} replace />
	}

	return <main className="main-container-logged-out ">{children ? children : <Outlet />}</main>
}

export default OpenRoute
