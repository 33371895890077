import React from 'react'

import { useTimeout } from '../hooks/useTimeout'

import { ReactComponent as AlertTriangleIcon } from 'svgs/alert-triangle.svg'
import { ReactComponent as LargeCrossIcon } from 'svgs/large-cross.svg'
import { ReactComponent as GreenCheckIcon } from 'svgs/green-check.svg'

const icons = {
	success: <GreenCheckIcon />,
	error: <AlertTriangleIcon />
}

export function Toast({ children, type = 'info', close }) {
	const timeout = type === 'error' ? 25000 : 10000
	useTimeout(close, timeout)

	return (
		<div className={`toast ${type}`}>
			<div className="toast-icon">{icons[type]}</div>
			<div className="toast-message">{children}</div>
			<div className="toast-close-container" onClick={close}>
				<button className={`toast-close-btn ${type}`}>
					<LargeCrossIcon />
				</button>
			</div>
		</div>
	)
}
