import React from 'react'

import ReactModal from 'react-modal'

import { ReactComponent as LargeCrossIcon } from 'svgs/large-cross.svg'

import './index.scss'

ReactModal.setAppElement('#root')

export function Modal({ children, headerLabel, contentLabel, isOpen, closeModal }) {
	return (
		<ReactModal isOpen={isOpen} onRequestClose={closeModal} contentLabel={contentLabel} closeTimeoutMS={500} className="Modal">
			{headerLabel && (
				<>
					<button className="modal-close-btn" type="button" onClick={closeModal}>
						<LargeCrossIcon />
					</button>
					<div className="modal-header">
						<h2>{headerLabel}</h2>
					</div>
				</>
			)}
			<div className="modal-body">{children}</div>
		</ReactModal>
	)
}
