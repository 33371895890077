import React from 'react'

import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AccountProvider } from 'features/accounts/AccountContext'
import { AuthProvider } from 'features/auth'
import { ToastProvider } from 'components/atoms/Toast/ToastProvider'
import ErrorFallback from 'components/sections/ErrorFalback'
import { ModalContextProvider } from 'contexts/ModalContext'

import App from './App'

import './index.scss'

const renderApp = () => {
	const queryClient = new QueryClient()

	const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]

	if (path) {
		history.replace(path)
	}

	queryClient.setDefaultOptions({
		queries: {
			staleTime: 3 * (60 * 1000) // 3 mins
		}
	})

	const root = createRoot(document.getElementById('root'))

	root.render(
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<ToastProvider>
					<ErrorBoundary FallbackComponent={ErrorFallback} key={location.pathname}>
						<Suspense fallback={<div className="main-container" />}>
							<AuthProvider>
								<AccountProvider>
									<ModalContextProvider>
										<App />
									</ModalContextProvider>
								</AccountProvider>
							</AuthProvider>
						</Suspense>
					</ErrorBoundary>
				</ToastProvider>
			</BrowserRouter>
		</QueryClientProvider>
	)
}

renderApp()
