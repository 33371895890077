import React from 'react'

import { Link } from 'react-router-dom'

import { ReactComponent as ElocalLogo } from 'svgs/elocal-logo.svg'

import './index.scss'

function Logo() {
	return (
		<Link to="/" className="main-logo">
			<ElocalLogo />
		</Link>
	)
}

export default Logo
