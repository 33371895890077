import React from 'react'

import { useNavigate, createSearchParams } from 'react-router-dom'
import { useAuth } from 'features/auth'
import { cleanError } from 'utils'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import InputGroup from 'components/atoms/InputGroup'
import Button from 'components/UI/Button'

const schema = object().shape({
	username: string().required('This field is required')
})

function ForgotPassword() {
	const [error, setError] = React.useState(null)
	const { forgotPassword } = useAuth()

	const navigate = useNavigate()

	const handleSubmit = async (values) => {
		try {
			await forgotPassword(values.username)
			navigate({
				pathname: '/create-new-password',
				search: createSearchParams({ userName: values.username }).toString()
			})
		} catch (error) {
			setError(cleanError(error))
		}
	}

	return (
		<Formik initialValues={{ username: '' }} validationSchema={schema} onSubmit={handleSubmit}>
			<Form>
				<div className="welcome-heading">Forgot Password</div>
				<div className="form-main">
					<InputGroup name="username" placeholder="Email or Phone Number" />
				</div>
				<div className="form-footer">
					<div className="actions-container">
						<Button type="submit" color="primary" variant="contained" fullWidth>
							Submit
						</Button>
						{error && <div className="error">{error}</div>}
					</div>
				</div>
			</Form>
		</Formik>
	)
}

export default ForgotPassword
