import { useQueryClient, useMutation } from '@tanstack/react-query'
import createFetch from 'api/fetchApi'
import { salesForceUrl } from 'api/api-url'
import accountKeys from './accountKeys'
import { useAccountId } from 'features/accounts/AccountContext'

const fetchApi = createFetch(salesForceUrl)

const updateAccount = (accountId) => async (account) => {
	const response = await fetchApi(`/${accountId}`, {
		method: 'PUT',
		body: JSON.stringify(account)
	})

	return response
}

export default function useUpdateAccount() {
	const queryClient = useQueryClient()
	const { accountId } = useAccountId()
	return useMutation({
		mutationFn: updateAccount(accountId),
		suspense: true,
		onSuccess: () => {
			queryClient.invalidateQueries([accountId, ...accountKeys.list()])
		}
	})
}
