import { useContext } from 'react'

import { AccountRoleContext } from './AccountRoleContext'
import { RolesOrder } from './RolesOrder'

export function useAccountRole(roleNeeded) {
	const context = useContext(AccountRoleContext)
	if (context === undefined) {
		throw new Error('useAccountRole must be used inside AccountProvider')
	}

	context.hasPermission = context.isAdmin || RolesOrder.indexOf(context.accountRole) <= RolesOrder.indexOf(roleNeeded)

	return context
}
