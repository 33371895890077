import React from 'react'

import { useState } from 'react'
import { useAuth } from 'features/auth'
import { cleanError } from 'utils'
import { useToast } from 'components/atoms/Toast'
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import InputGroup from 'components/atoms/InputGroup'
import Button from 'components/UI/Button'

const schema = object().shape({
	verificationCode: string().required('Please enter verification code'),
	username: string().required('Please enter your username')
})

function ConfirmRegisterContent() {
	const [error, setError] = useState(null)

	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)
	const queryParamsObject = Object.fromEntries([...queryParams])
	const toast = useToast()
	const navigate = useNavigate()
	const { confirmRegister, resendConfirmationCode } = useAuth()

	const handleSubmit = async (values, { setSubmitting }) => {
		const { username, verificationCode } = values

		try {
			await confirmRegister(username, verificationCode)
			toast.open('User Verified! Please Login', 'info')
			navigate({
				pathname: 'login',
				search: createSearchParams({ username }).toString()
			})
		} catch (error) {
			console.error(error)
			if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
				toast.open('User already confirmed please login', 'info')
				navigate({
					pathname: 'login',
					search: createSearchParams({ username }).toString()
				})
			}
			setError(cleanError(error))
		} finally {
			setSubmitting(false)
		}
	}

	const resendCode = async (values) => {
		setError('')
		if (!values.username) {
			setError('Please enter username')
			return
		}
		try {
			await resendConfirmationCode(values.username)
			toast.open('Verification code was sent to your Email', 'info')
		} catch (error) {
			setError(cleanError(error))
		}
	}

	return (
		<Formik
			initialValues={{
				username: queryParamsObject.username || '',
				verificationCode: ''
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}
		>
			{({ values }) => (
				<Form>
					<div className="form-header">Register User</div>
					<p style={{ padding: '0 10px' }}>Enter Verification Code sent on your Email to Confirm Registration</p>
					<div className="form-main">
						<InputGroup name="username" placeholder="Enter email or phone number" />
						<InputGroup name="verificationCode" type="password" placeholder="Code" />
					</div>
					<div className="form-footer">
						<div className="actions-container">
							<Button type="submit" color="primary" variant="contained" fullWidth>
								Confirm
							</Button>
							<p>Not Received Verification Code? Please resend</p>
							<Button color="primary" onClick={() => resendCode(values)} type="button">
								Resend Verification Code
							</Button>
							{error && <div className="error">{error}</div>}
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default ConfirmRegisterContent
