import React from 'react'

import { addDays } from 'date-fns'
import { useField } from 'formik'
import DateRangeCalendar from 'components/atoms/DateRangeCalendar'

function DatePickerInput({ name }) {
	const [meta, helpers] = useField(name)
	const { value } = meta
	const { setValue } = helpers

	return (
		<div className="date-field-wrapper date-range-calendar">
			<DateRangeCalendar
				value={value}
				onChange={(item) => {
					setValue({ ...value, ...item })
				}}
				minDate={addDays(new Date(), -300)}
				maxDate={addDays(new Date(), 900)}
			/>
		</div>
	)
}

export default DatePickerInput
