import React from 'react'

import { useState } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { createPortal } from 'react-dom'
import { ToastContext } from './ToastContext'
import { Toast } from './Toast'

// Create a random ID
function generateUEID() {
	let first = (Math.random() * 46656) | 0
	let second = (Math.random() * 46656) | 0
	first = ('000' + first.toString(36)).slice(-3)
	second = ('000' + second.toString(36)).slice(-3)

	return first + second
}

export const ToastProvider = (props) => {
	const [toasts, setToasts] = useState([])
	const [listRef] = useAutoAnimate()

	const open = (content, type) => setToasts((currentToasts) => [...currentToasts, { id: generateUEID(), content, type }])
	const error = (content) => open(content, 'error')
	const success = (content) => open(content, 'success')
	const close = (id) => setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id))

	return (
		<ToastContext.Provider value={{ open, error, success }}>
			{props.children}

			{createPortal(
				<div className="toasts-wrapper" ref={listRef}>
					{toasts.map((toast) => (
						<Toast key={toast.id} type={toast.type} close={() => close(toast.id)}>
							{toast.content}
						</Toast>
					))}
				</div>,
				document.body
			)}
		</ToastContext.Provider>
	)
}
