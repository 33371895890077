import React from 'react'

import { Suspense } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import loadable from '@loadable/component'

import { useAuth } from 'features/auth'
import ErrorFallback from 'components/sections/ErrorFalback'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

import { OpenRoute, ProtectedRoute } from 'components/auth'

const Account = loadable(() => import('pages/Account'))
const AccountDashboard = loadable(() => import('pages/AccountDashboard'))
const AccountSelection = loadable(() => import('pages/AccountSelection'))
const AdminAccountSelect = loadable(() => import('pages/AdminAccountSelect'))
const Billing = loadable(() => import('pages/Billing'))
const BudgetDetail = loadable(() => import('pages/BudgetDetail'))
const CallDetail = loadable(() => import('pages/CallDetail'))
const CampaignDashboard = loadable(() => import('pages/CampaignDashboard'))
const CampaignsList = loadable(() => import('pages/CampaignsList'))
const CampaignListings = loadable(() => import('pages/CampaignListings'))
const CampaignListingDashboard = loadable(() => import('pages/CampaignListingDashboard'))
const ChangePassword = loadable(() => import('components/sections/ChangePassword'))
const ContactUs = loadable(() => import('pages/ContactUs'))
const EmployeeLoginPage = loadable(() => import('pages/EmployeeLogin'))
const ForgotPassword = loadable(() => import('pages/ForgotPassword'))
const LeadDetail = loadable(() => import('pages/LeadDetail'))
const ListingDashboard = loadable(() => import('pages/ListingDashboard'))
const Login = loadable(() => import('pages/Login'))
const NewPassword = loadable(() => import('pages/NewPassword'))
const NewPasswordLogin = loadable(() => import('pages/NewPasswordLogin'))
const Otp = loadable(() => import('pages/Otp'))
const Profile = loadable(() => import('pages/Profile'))
const RegisterUser = loadable(() => import('pages/RegisterUser'))

const LoadingView = () => (
	<div className="page-loading-wrap">
		<LoadingSpinner />
	</div>
)

function App() {
	const { auth, isLoading } = useAuth()
	const location = useLocation()

	if (!auth && isLoading) {
		return <LoadingView />
	}

	return (
		<>
			<ErrorBoundary FallbackComponent={ErrorFallback} key={location.pathname}>
				<Suspense
					fallback={
						<main className="main-container">
							<LoadingView />
						</main>
					}
				>
					<>
						<Routes>
							<Route element={<OpenRoute />}>
								<Route path="create-new-password" element={<NewPassword />} />
								<Route path="new-password" element={<NewPasswordLogin />} />
								<Route path="confirm" element={<RegisterUser />} />
								<Route path="login" element={<Login />} />
								<Route path="otp" element={<Otp />} />
								<Route path="forgot-password" element={<ForgotPassword />} />
								<Route path="employee-login" element={<EmployeeLoginPage />} />
								<Route path="*" element={<Navigate to="/login" />} />
							</Route>
							<Route element={<ProtectedRoute />}>
								<Route path="campaigns" element={<CampaignsList />} />
								<Route path="campaigns/:campaignId" element={<CampaignDashboard />} />
								<Route path="campaigns/:campaignId/listings" element={<CampaignListings />} />
								<Route path="campaigns/:campaignId/listings/:listingId" element={<CampaignListingDashboard />} />
								<Route path="listings/:listingId" element={<ListingDashboard />} />
								<Route path="budgets/:budgetId" element={<BudgetDetail />} />
								<Route path="account" element={<Account />} />
								<Route path="lead/:supplyEventId" element={<LeadDetail />} />
								<Route path="call/:supplyEventId" element={<CallDetail />} />
								<Route path="profile" element={<Profile />} />
								<Route path="billing" element={<Billing />} />
								<Route path="account-select" element={<AccountSelection />} />
								<Route path="change-password" element={<ChangePassword />} />
								<Route path="admin/:accountId" element={<AdminAccountSelect />} />
								<Route path="contact-us" element={<ContactUs />} />
								<Route path="/" index element={<AccountDashboard />} />
								<Route path="*" element={<Navigate to="/account" />} />
							</Route>
							<Route path="*" element={<p>There&apos;s nothing here: 404!</p>} />
						</Routes>
					</>
				</Suspense>
			</ErrorBoundary>
		</>
	)
}

export default App
