import React from 'react'

import './index.scss'

import { NavLink } from 'react-router-dom'

import { ReactComponent as HomeIcon } from 'svgs/home-icon.svg'
import { ReactComponent as MegaPhone } from 'svgs/mega-phone.svg'
import { ReactComponent as BillingIcon } from 'svgs/billing.svg'

function Footer() {
	return (
		<footer>
			<div className="links-container">
				<NavLink to="/" className="nav-link">
					<div className="icon-container">
						<HomeIcon />
					</div>
					<label>Summary</label>
				</NavLink>
				<NavLink to="/campaigns" className="nav-link">
					<div className="icon-container">
						<MegaPhone />
					</div>
					<label>Campaigns</label>
				</NavLink>
				<NavLink to="/billing" className="nav-link">
					<div className="icon-container">
						<BillingIcon />
					</div>
					<label>Billing</label>
				</NavLink>
			</div>
		</footer>
	)
}

export default Footer
