import React from 'react'

import '../styles/account-detail-panel.scss'

import { useState } from 'react'
import { Panel, PanelHeader, PanelBody, PanelTitle } from 'components/atoms/Panel'
import AccountDetails from '../components/AccountDetails'
import Button from 'components/UI/Button'
import AccountName from './AccountName'
import AccountForm from '../components/AccountForm'
import { useModalContext } from 'contexts/ModalContext'

import { ReactComponent as ChevronDownIcon } from 'svgs/chevron-down.svg'

import { ReactComponent as EditIcon } from 'svgs/edit.svg'

function AccountDetailsView() {
	const [open, setOpen] = useState(false)
	const modalContext = useModalContext()
	const handleEditAccountClick = () => {
		modalContext.showModal('Edit Account', ({ handleHide }) => <AccountForm handleHide={handleHide} />)
	}

	return (
		<Panel className="account-detail-panel">
			<PanelHeader>
				<div className="account-label">Account Name</div>
				<PanelTitle>
					<AccountName />
				</PanelTitle>
				<button className="view-more" onClick={() => setOpen((open) => !open)}>
					<ChevronDownIcon />
					View more
				</button>
			</PanelHeader>
			<PanelBody className={open ? 'show-body' : 'hide-body'}>
				<AccountDetails />
				<Button onClick={handleEditAccountClick} color="secondary" fullWidth>
					<EditIcon />
					Edit Details
				</Button>
			</PanelBody>
		</Panel>
	)
}

export default AccountDetailsView
