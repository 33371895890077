import { applicationSettings } from 'constants'

const baseUrl = applicationSettings.VIKING_API

export const accountListUrl = `${baseUrl}/api/rest`
export const accountUrl = `${baseUrl}/account-management`
export const gtvUrl = `${baseUrl}/gtv/billing-account`
export const gtvPaymentUrl = `${baseUrl}/gtv/payment`
export const gtvInvoice = `${baseUrl}/gtv/invoice`
export const indexerUrl = `${baseUrl}/viking-indexer`
export const listingProcessorUrl = `${baseUrl}/listing-processor`
export const notificationUrl = `${baseUrl}/notification-svc`
export const salesForceUrl = `${baseUrl}/ad-portal/advprtl/accounts`
export const salesForceBaseUrl = `${baseUrl}/ad-portal/advprtl`
export const typeAheadUrl = `${baseUrl}/type-ahead`
export const webhookConfigUrl = `${baseUrl}/account-management/webhook-config`

export default baseUrl
