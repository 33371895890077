import React from 'react'

import { useState } from 'react'
import { Form, Formik } from 'formik'
import { string, object, ref } from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'features/auth'
import { cleanError } from 'utils'
import InputGroup from 'components/atoms/InputGroup'
import Button from 'components/UI/Button'
import './index.scss'

const schema = object().shape({
	username: string().required('This field is required'),
	password: string().required('This field is required'),
	newPassword: string().required('This field is required'),
	confirmPassword: string()
		.required('Please retype your password.')
		.oneOf([ref('newPassword')], 'Your passwords do not match')
})

function Login() {
	const [error, setError] = useState(null)
	const { authenticate } = useAuth()
	const navigate = useNavigate()
	const handleSubmit = async (values, { setSubmitting }) => {
		const { username } = values

		try {
			await authenticate(values.username, values.password, values.newPassword)
		} catch (err) {
			if (err.message === 'User is not confirmed.') {
				navigate(`/confirm?username=${username}`)
			}
			setError(cleanError(err))
			setSubmitting(false)
		}
	}
	return (
		<Formik
			initialValues={{
				username: '',
				password: '',
				newPassword: '',
				confirmPassword: ''
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}
		>
			<Form>
				<div className="form-header">Create Password and Login</div>
				<div className="form-main">
					<InputGroup label="Username" name="username" />
					<InputGroup label="Temp Password" name="password" type="password" />
					<InputGroup label="New Password" name="newPassword" type="password" />
					<InputGroup label="Confirm New Password" name="confirmPassword" type="password" />
				</div>
				<div className="form-footer">
					<div className="actions-container">
						<Button type="submit" color="primary" variant="contained" fullWidth>
							Login
						</Button>
						<Link to="/forgot-password">Forgot Password</Link>
						{error && <div className="error">{error}</div>}
					</div>
				</div>
			</Form>
		</Formik>
	)
}

export default Login
