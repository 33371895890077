import React from 'react'

import './index.scss'

import { useNavigate } from 'react-router-dom'

import Button from 'components/UI/Button'
// import Header from 'components/sections/Header'

import { ReactComponent as SomethingWentWrong } from 'svgs/something-went-wrong.svg'

function ErrorFallback({ resetErrorBoundary }) {
	const navigate = useNavigate()
	const onReset = () => {
		navigate('/campaigns')
		resetErrorBoundary()
	}
	return (
		<div className="content">
			{/* <Header /> */}

			<main className="main-container">
				<div role="alert" className="error-fallback">
					<SomethingWentWrong />
					<h1>Something went wrong!</h1>
					<p>Please Try again later</p>
					<Button color="primary" size="large" onClick={onReset}>
						Try again
					</Button>
				</div>
			</main>
		</div>
	)
}

export default ErrorFallback
