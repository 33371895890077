import React from 'react'

import useAccount from '../api/useAccount'
import { IconValueDisplay, IconValueDisplayWrap } from 'components/atoms/IconValueDisplay'

import { ReactComponent as BillingTypeIcon } from 'svgs/billing.svg'
import { ReactComponent as CircleDollarIcon } from 'svgs/circle-dollar-sign.svg'
import { ReactComponent as InvoiceIcon } from 'svgs/ico_Invoice_Date.svg'
import { ReactComponent as PendingChargesIcon } from 'svgs/ico_Pending_Charges.svg'

function AccountDetail() {
	const { data: account } = useAccount()
	const accountManager = account.Account_Manager__r
	return (
		<IconValueDisplayWrap>
			<IconValueDisplay value={account.Business_Formal_Name__c} label="Formal Name" icon={<BillingTypeIcon />} />
			<IconValueDisplay value={account.Phone} label="Phone" icon={<InvoiceIcon />} />
			<IconValueDisplay value={accountManager?.Name} label="Elocal Contact" icon={<PendingChargesIcon />} />
			<IconValueDisplay value={accountManager?.Email} label="Elocal Contact Email" icon={<CircleDollarIcon />} />
		</IconValueDisplayWrap>
	)
}

export default AccountDetail
