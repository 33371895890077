import React from 'react'

import './index.scss'

import { useState } from 'react'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import { Link, useNavigate, createSearchParams } from 'react-router-dom'

import { useAuth } from 'features/auth'
import { cleanError } from 'utils'
import InputGroup from 'components/atoms/InputGroup'
import Button from 'components/UI/Button'

const schema = object().shape({
	username: string().required('This field is required'),
	password: string().required('This field is required')
})

function Login() {
	const [error, setError] = useState(null)
	const { authenticate } = useAuth()

	const navigate = useNavigate()

	const handleSubmit = async (values, { setSubmitting }) => {
		const { username, password } = values
		try {
			const user = await authenticate(username, password)
			if (user.newPasswordRequired) {
				navigate(`/new-password`)
			}
		} catch (error) {
			if (error.message === 'User is not confirmed.') {
				navigate({
					pathname: 'confirm',
					search: createSearchParams({ username }).toString()
				})
			}
			setError(cleanError(error))
			setSubmitting(false)
		}
	}

	return (
		<Formik initialValues={{ username: '', password: '' }} validationSchema={schema} onSubmit={handleSubmit}>
			<Form>
				<h1 className="welcome-heading">Welcome</h1>
				<p className="tag">Enter your credentials to access your account</p>
				<div className="form-main">
					<InputGroup name="username" placeholder="Email or Phone Number" />
					<InputGroup name="password" type="password" placeholder="Password" />
				</div>
				<div className="form-footer">
					<div className="actions-container">
						<Button type="submit" color="primary" variant="contained" fullWidth>
							Login
						</Button>
						<Button type="button" color="primary" variant="contained" fullWidth onClick={() => navigate(`/otp`)}>
							Get one-time code
						</Button>
						<Link to="/forgot-password">Forgot Password</Link>
						{error && <div className="error">{error}</div>}
					</div>
				</div>
			</Form>
		</Formik>
	)
}

export default Login
