import React from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'features/auth'
import { cleanError } from 'utils'
import { Form, Formik } from 'formik'
import { string, object, ref } from 'yup'
import InputGroup from 'components/atoms/InputGroup'
import Button from 'components/UI/Button'

const CreateNewPassword = () => {
	const [error, setError] = React.useState()
	const [searchParams] = useSearchParams()

	const schema = object().shape({
		verificationCode: string().required('Please enter verification code'),
		password: string().required('Please enter your password'),
		confirmPassword: string()
			.required('Please retype your password.')
			.oneOf([ref('password')], 'Your passwords do not match')
	})

	const navigate = useNavigate()
	const userName = searchParams.get('userName')
	const { confirmPassword } = useAuth()

	const handleSubmit = (values, { setSubmitting }) => {
		confirmPassword(userName, values.verificationCode, values.password)
			.then(() => navigate('/login'))
			.catch((err) => setError(cleanError(err)))
			.finally(() => setSubmitting(false))
	}

	return (
		<Formik
			initialValues={{
				verificationCode: '',
				password: '',
				confirmPassword: ''
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}
		>
			<Form>
				<div className="welcome-heading">Create New Password</div>
				<div className="form-main">
					<InputGroup name="verificationCode" label="Verification Code" />
					<InputGroup label="Password" name="password" type="password" />
					<InputGroup label="Confirm Password" name="confirmPassword" type="password" />
				</div>
				<div className="form-footer">
					<div className="actions-container">
						<Button type="submit" color="primary" variant="contained" fullWidth>
							Submit
						</Button>
						{error && <div className="error">{error}</div>}
					</div>
				</div>
			</Form>
		</Formik>
	)
}

export default CreateNewPassword
