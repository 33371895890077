import React from 'react'

import './index.scss'

function LoadingSpinner() {
	return (
		<div className="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}

export default LoadingSpinner
