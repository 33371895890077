import React from 'react'

import { createContext, useState, useEffect } from 'react'
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoIdToken,
	CognitoRefreshToken,
	CognitoUserSession,
	CognitoAccessToken
} from 'amazon-cognito-identity-js'

import { userPool } from 'constants'

const AuthContext = createContext()

function AuthProvider(props) {
	const [isLoading, setIsLoading] = useState(true)
	const [auth, setAuth] = useState(null)

	useEffect(() => {
		setIsLoading(true)

		const check = async () => {
			const session = await getSession()

			if (session) {
				setAuth(session)
			}

			setIsLoading(false)
		}

		check()
	}, [])

	const authenticate = async (Username, Password, newPassword) => {
		return await new Promise((resolve, reject) => {
			const user = new CognitoUser({ Username, Pool: userPool })

			const authDetails = new AuthenticationDetails({ Username, Password })

			user.authenticateUser(authDetails, {
				onSuccess: async (data) => {
					const accountFromSession = await getSession()
					setAuth(accountFromSession)
					resolve(data)
				},
				onFailure: (err) => reject(err),
				newPasswordRequired: async (userAttributes) => {
					const attributes = {
						email: userAttributes.email,
						name: userAttributes.name
					}
					if (!newPassword) {
						attributes.newPasswordRequired = true
						resolve(attributes)
					} else {
						user.completeNewPasswordChallenge(
							newPassword,
							{},
							{
								onSuccess: async (data) => {
									const accountFromSession = await getSession()
									setAuth(accountFromSession)
									resolve(data)
								},
								onFailure: (error) => {
									reject(error)
								}
							}
						)
					}
				}
			})
		})
	}

	const passwordlessAuth = async (Username) => {
		return await new Promise((resolve, reject) => {
			const authenticationDetails = new AuthenticationDetails({ Username })
			const cognitoUser = new CognitoUser({ Username, Pool: userPool })
			cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')

			cognitoUser.initiateAuth(authenticationDetails, {
				onSuccess: (result) => {
					resolve(result)
				},
				onFailure: (err) => {
					reject(err)
				},
				customChallenge: (challengeParameters) => {
					// User authentication depends on challenge response
					resolve(challengeParameters)
					sessionStorage.setItem('cognitoUser', JSON.stringify(cognitoUser))
				}
			})
		})
	}

	const answerAuth = async (Username, verificationCode) => {
		return await new Promise((resolve, reject) => {
			const user = JSON.parse(sessionStorage.getItem('cognitoUser'))

			const cognitoUser = new CognitoUser({ Username, Pool: userPool })
			cognitoUser.Session = user.Session

			cognitoUser.sendCustomChallengeAnswer(verificationCode, {
				async onSuccess(success) {
					resolve(success)
					try {
						// This will throw an error if the user is not yet authenticated:
						const accountFromSession = await getSession()
						setAuth(accountFromSession)
					} catch {
						reject('Invalid verification code')
					}
				},
				onFailure(failure) {
					reject(failure)
				}
			})
		})
	}

	const confirmPassword = async (Username, verificationCode, newPassword) => {
		return await new Promise((resolve, reject) => {
			const user = new CognitoUser({ Username, Pool: userPool })

			user.confirmPassword(verificationCode, newPassword, {
				onSuccess: (data) => {
					resolve(data)
				},
				onFailure: (err) => {
					reject(err)
				}
			})
		})
	}

	const getSession = async () => {
		return await new Promise((resolve) => {
			const user = userPool.getCurrentUser()

			if (!user) {
				resolve(null)
			}
			user.getSession((err, session) => {
				if (err) {
					console.error(err)
					throw new Error('Error getting user Session')
				}

				user.getUserAttributes((err, attributesArray) => {
					if (err) {
						console.error(err)
						throw new Error('Error getting user Attributes ')
					}
					const attributes = attributesArray.reduce((acc, attribute) => {
						acc[attribute.Name] = attribute.Value
						return acc
					}, {})
					const account = { user, ...session, ...attributes }
					if (account[`custom:is_internal`] === 'Y' || user.username.includes('azuread')) {
						account.isAdmin = true
					}
					resolve(account)
				})
			})
		})
	}

	const forgotPassword = async (Username) => {
		return await new Promise((resolve, reject) => {
			const user = new CognitoUser({ Username, Pool: userPool })

			user.forgotPassword({
				onSuccess: (data) => {
					resolve(data)
				},
				onFailure: (err) => {
					reject(err)
				}
			})
		})
	}

	const logout = async () => {
		return await new Promise((resolve, reject) => {
			const user = userPool.getCurrentUser()
			if (user) {
				user.signOut()
				setAuth(null)
				resolve({ logout: true })
			} else {
				setAuth(null)
				reject()
			}
		})
	}

	const register = async (email, password) => {
		return await new Promise((resolve, reject) => {
			const attributes = [{ Name: 'email', Value: email }]
			userPool.signUp(email, password, attributes, null, (err, data) => {
				if (err) {
					reject(err)
				} else {
					resolve(data)
				}
			})
		})
	}

	const confirmRegister = async (userName, verificationCode) => {
		return await new Promise((resolve, reject) => {
			const userData = {
				Username: userName,
				Pool: userPool
			}

			const cognitoUser = new CognitoUser(userData)
			cognitoUser.confirmRegistration(verificationCode, true, (err, data) => {
				if (err) {
					reject(err)
				} else {
					resolve(data)
				}
			})
		})
	}

	const resendConfirmationCode = async (userName) => {
		return await new Promise((resolve, reject) => {
			const userData = {
				Username: userName,
				Pool: userPool
			}

			const cognitoUser = new CognitoUser(userData)

			cognitoUser.resendConfirmationCode((err, data) => {
				if (err) {
					reject(err)
				} else {
					resolve(data)
				}
			})
		})
	}

	const updateUserAttributes = async ({ email, name }) => {
		const user = userPool.getCurrentUser()
		await new Promise((res) => user.getSession(res))
		const params = {
			AccessToken: auth.accessToken.jwtToken,
			UserAttributes: []
		}

		if (email) {
			params.UserAttributes.push({ Name: 'email', Value: email })
		}
		if (name) {
			params.UserAttributes.push({ Name: 'name', Value: name })
		}
		return await new Promise((resolve, reject) => {
			user.updateAttributes(params.UserAttributes, (err, data) => {
				if (err) {
					reject(err)
				} else {
					resolve(data)
				}
			})
		}).then(async () => {
			const session = await getSession()
			setAuth(session)
		})
	}
	// https://stackoverflow.com/questions/61644513/setting-aws-amplify-user-session-manually
	// https://blog.founderatwork.com/how-to-authenticate-users-with-tokens-using-cognito/
	async function authWithTokens(idToken, accessToken, refreshToken) {
		const cognitoIdToken = new CognitoIdToken({
			IdToken: idToken
		})
		const cognitoAccessToken = new CognitoAccessToken({
			AccessToken: accessToken
		})
		const cognitoRefreshToken = new CognitoRefreshToken({
			RefreshToken: refreshToken
		})
		const username = cognitoIdToken.payload['cognito:username']
		const user = new CognitoUser({
			Username: username,
			Pool: userPool
		})
		user.setSignInUserSession(
			new CognitoUserSession({
				AccessToken: cognitoAccessToken,
				IdToken: cognitoIdToken,
				RefreshToken: cognitoRefreshToken
			})
		)
		const accountFromSession = await getSession()
		setAuth(accountFromSession)
	}

	return (
		<AuthContext.Provider
			value={{
				isLoading,
				authenticate,
				confirmPassword,
				forgotPassword,
				getSession,
				logout,
				register,
				confirmRegister,
				resendConfirmationCode,
				passwordlessAuth,
				answerAuth,
				updateUserAttributes,
				authWithTokens,
				auth
			}}
		>
			{props.children}
		</AuthContext.Provider>
	)
}

export { AuthProvider, AuthContext }
