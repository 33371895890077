import './index.scss'

import { useState } from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export function useModal(initalState = false) {
	const [isOpen, setIsOpen] = useState(initalState)
	const openModal = () => setIsOpen(true)
	const closeModal = () => setIsOpen(false)

	return { isOpen, openModal, closeModal }
}
