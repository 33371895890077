import React from 'react'

import { NavLink, useNavigate } from 'react-router-dom'

import FlexSpacer from 'components/atoms/FlexSpacer'
import Logo from 'components/atoms/Logo'
import { PopperOverlay } from 'components/atoms/PopperOverlay'
import { useAccountId } from 'features/accounts'
import { useAuth } from 'features/auth'

import { ReactComponent as BillingIcon } from 'svgs/billing.svg'
import { ReactComponent as GearIcon } from 'svgs/gear.svg'
import { ReactComponent as HomeIcon } from 'svgs/home-icon.svg'
import { ReactComponent as LogoutIcon } from 'svgs/logout.svg'
import { ReactComponent as MegaPhone } from 'svgs/mega-phone.svg'
import { ReactComponent as PhoneIcon } from 'svgs/phone-new.svg'
import { ReactComponent as ProfileIcon } from 'svgs/profile.svg'
import { ReactComponent as SwitchIcon } from 'svgs/switch-account.svg'

import './index.scss'

function Header() {
	const { logout, auth } = useAuth()
	const { removeAccountId } = useAccountId()
	const firstLetter = auth.idToken.payload.name.substring(0, 1)
	const navigate = useNavigate()

	const handleLogout = async () => {
		await logout()
		removeAccountId()
		navigate('/login')
	}

	return (
		<header>
			<div className={'container main'}>
				<div className="main-logo-container">
					<Logo />
				</div>
				<FlexSpacer />
				<ul>
					<li className="nav-link">
						<NavLink to="/">
							<HomeIcon />
							Summary
						</NavLink>
					</li>
					<li className="nav-link">
						<NavLink to="/campaigns">
							<MegaPhone /> Campaigns
						</NavLink>
					</li>
					<li className="nav-link">
						<NavLink to="/billing">
							<BillingIcon /> Billing
						</NavLink>
					</li>
					<li>
						<PopperOverlay buttonText={firstLetter}>
							{() => (
								<>
									<ul className="drop-down-nav">
										<li>
											<NavLink to="/account">
												<GearIcon /> Account
											</NavLink>
										</li>
										<li>
											<NavLink to="/profile">
												<ProfileIcon /> Profile
											</NavLink>
										</li>

										<li onClick={() => false}>
											<NavLink to="/account-select">
												<SwitchIcon /> Switch Account
											</NavLink>
										</li>
										<li>
											<NavLink to="/contact-us">
												<PhoneIcon /> Contact Us
											</NavLink>
										</li>
										<li onClick={handleLogout}>
											<a href="">
												<LogoutIcon /> Sign Out
											</a>
										</li>
									</ul>
								</>
							)}
						</PopperOverlay>
					</li>
				</ul>
			</div>
		</header>
	)
}

export default Header
