import React from 'react'

import '../styles/account-form.scss'

import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import useUpdateAccount from '../api/useUpdateAccount'
import InputGroup from 'components/atoms/InputGroup'
import { timezones } from 'utils'
import { SubmitButton, StatusBanner, FormActions } from 'components/atoms/Form'
import Button from 'components/UI/Button'
import { pick } from 'lodash-es'
import useAccount from '../api/useAccount'

const initValues = {
	Name: '',
	Business_Formal_Name__c: '',
	Email: '',
	Phone: '',
	Time_Zone__c: '',
	Website: '',
	Fax: ''
}

const schema = object().shape({
	Name: string().required('This field is required'),
	Business_Formal_Name__c: string().required('This field is required'),
	Email: string(),
	Phone: string()
})

function AccountForm({ handleHide }) {
	const { data: account } = useAccount()
	const { mutate: updateAccount } = useUpdateAccount()
	const accountFormValues = pick(account, ['Name', 'Business_Formal_Name__c', 'Email', 'Phone', 'Time_Zone__c'])

	const handleSubmit = (values, { setSubmitting, setStatus }) => {
		values.Phone = values.Phone.slice(0, 2) === '+1' ? values.Phone : `+1${values.Phone}`
		updateAccount(values, {
			onSuccess: () => {
				handleHide()
			},
			onError: () => {
				setStatus({
					message: 'There was an error updating your account',
					type: 'error'
				})
			},
			onSettled: () => {
				setSubmitting(false)
			}
		})
	}

	return (
		<Formik initialValues={{ ...initValues, ...accountFormValues }} validationSchema={schema} onSubmit={handleSubmit}>
			<Form className="account-form">
				<StatusBanner />
				<InputGroup label="Name" name="Name" disabled helperText="Must contact admin to update name" />
				<InputGroup label="Formal Name" name="Business_Formal_Name__c" />
				<InputGroup label="Phone" name="Phone" />
				<InputGroup label="Timezone" name="Time_Zone__c">
					<option value="" disabled></option>
					{timezones.map((timezone) => (
						<option key={timezone.abbreviation} value={timezone.abbreviation}>
							{timezone.abbreviation}
						</option>
					))}
				</InputGroup>
				<FormActions>
					<Button onClick={handleHide} color="secondary">
						Cancel
					</Button>
					<SubmitButton />
				</FormActions>
			</Form>
		</Formik>
	)
}

export default AccountForm
