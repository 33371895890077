/*
 This library is not mobile friendly, and causes display issues on mobile devices and small screens.
 See https://www.npmjs.com/package/react-date-range for more info.

 TODO: Replace this with a mobile friendly date range picker library
 Example: https://www.npmjs.com/package/react-date-range
*/

import React from 'react'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import './date-range-calendar.scss'

import { DateRangePicker, defaultStaticRanges } from 'react-date-range'
import { subDays, startOfMonth, isSameDay } from 'date-fns'

const filterdStaticRanges = defaultStaticRanges.filter((x) => x.label !== 'This Week' && x.label !== 'This Month')

const staticRanges = [
	...filterdStaticRanges,
	{
		label: 'Last 30 Days',
		range: () => ({
			startDate: subDays(new Date(), 29),
			endDate: new Date()
		}),
		isSelected(range) {
			const definedRange = this.range()
			return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
		}
	},
	{
		label: 'Last 60 Days',
		range: () => ({
			startDate: subDays(new Date(), 59),
			endDate: new Date()
		}),
		isSelected(range) {
			const definedRange = this.range()
			return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
		}
	},
	{
		label: 'Last 90 Days',
		range: () => ({
			startDate: subDays(new Date(), 89),
			endDate: new Date()
		}),
		isSelected(range) {
			const definedRange = this.range()
			return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
		}
	},
	{
		label: 'Month To Date',
		range: () => ({
			startDate: startOfMonth(new Date()),
			endDate: new Date()
		}),
		isSelected(range) {
			const definedRange = this.range()
			return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
		}
	}
]

function DateRangeFilter({ value = {}, onChange, showStaticRanges, maxDate, minDate }) {
	return (
		<div className="date-range-calendar">
			<DateRangePicker
				onChange={(item) => {
					const { startDate, endDate } = item.selection
					onChange({ startDate, endDate })
				}}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				months={1}
				ranges={[{ ...value, key: 'selection' }]}
				direction="horizontal"
				preventSnapRefocus={true}
				calendarFocus="backwards"
				showDateDisplay={false}
				maxDate={maxDate}
				minDate={minDate}
				inputRanges={[]}
				staticRanges={showStaticRanges ? staticRanges : []}
			/>
		</div>
	)
}

export default DateRangeFilter
