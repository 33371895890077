import React from 'react'

import { createContext, useState, useEffect } from 'react'

import { useAuth } from 'features/auth'

export const AccountContext = createContext()

export function AccountProvider(props) {
	const { auth } = useAuth()
	const getIt = localStorage.getItem('accountId')
	const [accountId, setAccountId] = useState(getIt)

	const setAcc = async (account) => {
		const id = account.Postgres_External_Key__c
		localStorage.setItem('accountId', id)
		setAccountId(id)
	}

	const removeAccountId = () => {
		localStorage.removeItem('accountId')
		setAccountId(null)
	}
	useEffect(() => {
		if (auth) {
			const idFromStorage = localStorage.getItem('accountId')
			if (!accountId && idFromStorage) {
				setAccountId(idFromStorage)
			}
		}
	}, [auth, accountId])

	return (
		<AccountContext.Provider
			value={{
				accountId,
				setAccountId: setAcc,
				removeAccountId
			}}
		>
			{props.children}
		</AccountContext.Provider>
	)
}
