import React from 'react'

import { useState } from 'react'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import { useNavigate, createSearchParams } from 'react-router-dom'

import { useAuth } from 'features/auth'
import { cleanError } from 'utils'
import InputGroup from 'components/atoms/InputGroup'
import Button from 'components/UI/Button'

const schema = {
	username: object().shape({
		username: string().required('This field is required')
	}),
	verificationCode: object().shape({
		verificationCode: string().required('This field is required')
	})
}

const OtpForm = () => {
	const { passwordlessAuth, answerAuth } = useAuth()

	const [error, setError] = useState(null)
	const [isRetrievingCode, setIsRetrievingCode] = useState(false)
	const [isResendingCode, setIsResendingCode] = useState(false)
	const [isVerifyingCode, setIsVerifyingCode] = useState(false)
	const [resent, setResent] = useState(null)
	const [username, setUserName] = useState(null)

	const navigate = useNavigate()

	const handleOTPSubmit = async (values) => {
		if (!isVerifyingCode) {
			setIsVerifyingCode(true)
			setError(null)
			try {
				const user = await answerAuth(username, values.verificationCode)
				if (user.newPasswordRequired) {
					navigate(`/new-password`)
				}
				setIsVerifyingCode(false)
				setError(false)
			} catch (err) {
				console.log(err)
				if (err.message === 'User is not confirmed.') {
					navigate({
						pathname: 'confirm',
						search: createSearchParams({ username }).toString()
					})
				}
				setIsVerifyingCode(false)
				setError(`We could not verify that passcode: ${cleanError(err)}`)
			}
		}
	}

	const handleRequestCode = async (values) => {
		if (!isRetrievingCode) {
			setIsRetrievingCode(true)
			setError(null)
			try {
				const challenge = await passwordlessAuth(values.username)
				setUserName(challenge.email)
				setIsRetrievingCode(false)
			} catch (err) {
				setError('We could not send a passcode to this account. Please check the account and try again.')
				setIsRetrievingCode(false)
			}
		}
	}

	const handleResendCode = async () => {
		if (!isResendingCode) {
			setIsResendingCode(true)
			setError(null)
			try {
				await passwordlessAuth(username)
				setResent(true)
				setIsResendingCode(false)
			} catch (err) {
				setError('We were unable to send a new passcode. Please try again.')
				setIsResendingCode(false)
			}
		}
	}

	if (username) {
		return (
			<Formik initialValues={{ verificationCode: '' }} validationSchema={schema.verificationCode} onSubmit={handleOTPSubmit}>
				<Form>
					<div className="welcome-heading">Enter Passcode</div>
					<p>Please enter the passcode sent to your email or phone.</p>
					<div className="form-main">
						<InputGroup name="verificationCode" type="text" placeholder="Passcode" />
					</div>
					<div className="form-footer">
						<div className="actions-container">
							<Button type="submit" disabled={isVerifyingCode} color="primary" variant="contained" fullWidth>
								{isVerifyingCode ? `Verifying...` : `Verify`}
							</Button>
							{!resent && !isResendingCode && (
								<p>
									You should receive a passcode in the next few minutes. If you do not receive one, you can{' '}
									<span className="resend-trigger" onClick={handleResendCode}>
										request a new one
									</span>
									.
								</p>
							)}
							{!resent && isResendingCode && <p>Sending new code...</p>}
							{resent && <div className="resend-success">New code sent</div>}
							{error && <div className="error">{error}</div>}
						</div>
					</div>
				</Form>
			</Formik>
		)
	}

	return (
		<Formik initialValues={{ username: '' }} validationSchema={schema.username} onSubmit={handleRequestCode}>
			<Form>
				<div className="welcome-heading">Get Code</div>
				<div className="form-main">
					<InputGroup name="username" placeholder="Email or Phone Number" />
				</div>
				<div className="form-footer">
					<div className="actions-container">
						<Button type="submit" disabled={isRetrievingCode} color="primary" variant="contained" fullWidth>
							{isRetrievingCode ? `Checking...` : `Get Code`}
						</Button>
						{error && <div className="error">{error}</div>}
					</div>
				</div>
			</Form>
		</Formik>
	)
}

export default OtpForm
