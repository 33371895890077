import './popper-overlay.scss'

import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from '@chakra-ui/popper'
import classNames from 'classnames'

// Define default props if needed
const defaultOptions = { placement: 'bottom-end' }

export const PopperOverlay = (props) => {
	const { buttonText, buttonClassName, children, options = defaultOptions, popperClassName } = props

	const [isOpen, setIsOpen] = useState(false)

	const { popperRef, referenceRef } = usePopper(options)

	const closePopperOverlay = () => setIsOpen(false)

	const handleOutsideClick = (event) => {
		const targetId = event.target.getAttribute('id')
		if (!targetId || !targetId.includes('react-select')) {
			setIsOpen(false)
		}
	}

	const togglePopperOverlay = () => setIsOpen(!isOpen)

	return (
		<OutsideClickHandler onOutsideClick={handleOutsideClick} useCapture>
			<button ref={referenceRef} className={classNames('popper-overlay-button', buttonClassName)} onClick={togglePopperOverlay}>
				{buttonText}
			</button>

			<div
				ref={popperRef}
				className={classNames('popper-drop-down', popperClassName, {
					'popper-tool-tip-hidden': !isOpen
				})}
			>
				{children({ closePopperOverlay })}
			</div>
		</OutsideClickHandler>
	)
}
