import { useQuery } from '@tanstack/react-query'
import createFetch from 'api/fetchApi'
import { useAccountId } from 'features/accounts/AccountContext'
import accountKeys from './accountKeys'
import { salesForceUrl } from 'api/api-url'
const fetchApi = createFetch(salesForceUrl)

async function getAccount({ queryKey }) {
	const id = queryKey.slice(-1)[0]
	const response = await fetchApi(`/${id}`)
	return response
}

export default function useAccount(suspense = true) {
	const { accountId } = useAccountId()

	return useQuery({
		queryKey: accountKeys.detail(accountId),
		queryFn: getAccount,
		suspense: suspense,
		enabled: !!accountId
	})
}
