import React from 'react'

import { useFormikContext } from 'formik'
import Button from 'components/UI/Button'
import { CgSpinner } from 'react-icons/cg'
import './index.scss'

function SubmitButton({ children = 'Submit', ...props }) {
	const { isSubmitting } = useFormikContext()

	return (
		<Button className="loading-button" type="submit" color="primary" variant="contained" {...props}>
			{children} {isSubmitting && <CgSpinner />}
		</Button>
	)
}

export default SubmitButton
