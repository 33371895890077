import React from 'react'

import classnames from 'classnames'
import { Link } from 'react-router-dom'

import './index.scss'

function Button({
	children,
	size = 'medium',
	type = 'button',
	color,
	variant = 'text',
	className = '',
	underline,
	style,
	fullWidth,
	to,
	onClick,
	href,
	...rest
}) {
	const classes = classnames({
		btn: true,
		[color]: true,
		[`btn-${size}`]: true,
		[`btn-${variant}`]: true,
		'full-width': fullWidth,
		underline: underline,
		[className]: true
	})

	if (href) {
		return (
			<a href={href} className={classes} style={style} {...rest}>
				{children}
			</a>
		)
	}
	if (to) {
		return (
			<Link to={to} className={classes} style={style} {...rest}>
				{children}
			</Link>
		)
	}
	return (
		<button className={classes} type={type} onClick={onClick} style={style} {...rest}>
			{children}
		</button>
	)
}

export default Button
