import React from 'react'

import { Suspense } from 'react'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

function SuspenseLoading({ children }) {
	return (
		<Suspense
			fallback={
				<div className="page-loading-wrap">
					<LoadingSpinner />
				</div>
			}
		>
			{children}
		</Suspense>
	)
}

export default SuspenseLoading
