import React from 'react'

import { useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

const customStyles = {
	container: (base) => ({
		...base,
		fontSize: 18,
		borderRadius: 20,
		minHeight: 'inital',
		fontWeight: 400
	}),
	control: (state) => ({
		border: '1px solid',
		display: 'flex',
		borderColor: state.isFocused ? 'var(--color-grey-10);' : 'var(--color-grey-50)',
		'&:hover': {
			borderColor: 'var(--color-grey-10);',
			boxShadow: 'none',
			borderWidth: 1
		},
		padding: '0 12px',
		borderRadius: 8,
		fontWeight: 500,
		lineHeight: '19.8px',
		fontSize: 14
	}),
	menu: (base) => ({
		...base,
		zIndex: 9999
	}),
	valueContainer: (base) => ({
		...base
	}),
	input: (styles) => ({
		...styles,
		height: 29
	})
}

/**
 *
 * This component is confusing but it handles a lot of cases so I thinks its worth it
 *
 * valueKey - This prop is used for when you return and object instead of a single value from getOptionsValues.
 *            It will use this key to grab the field you want to compare from the options and values
 * ex
 * values = [{id:1, name: "John Goodman"}, {id:2, name: "Jack Black"}]
 * options = [{id:1, name: "John Goodman"}, {id:2, name: "Jack Black"}, {id:3, name: "Tom Hanks"}]
 *
 * in this case you would pass the prop valueKey="id" so that getValue will return what you want
 *
 * if values is just a array of ids don't pass the valueKey field because you don't need to grab the value from inside the object
 * ex
 * values = [1,2]
 * options = [{id:1, name: "John Goodman"}, {id:2, name: "Jack Black"}, {id:3, name: "Tom Hanks"}]
 */
function FilterSelect({ field, form, options, isMulti = false, fetchOptions, valueKey, initialOptions, ...rest }) {
	let initOptions
	if (initialOptions) {
		initOptions = initialOptions
	} else {
		initOptions = isMulti ? field.value : [field.value]
	}

	// This is just for saving selected useState options so we can have the value be a list of ids while still showing the label
	const [asyncOptions, setAsyncOptions] = useState(initOptions)

	// This is not a default prop because rest.getOptionValue gets passed to the Select component and we
	// don't want to overide the default in react-select we just want it here. If you pass a getOptionValue
	// prop in we want them to be the same
	const getOptionValue = (value) => {
		if (!value) return value // This is needed for clearing a value to work with single value select
		if (rest.getOptionValue) {
			return rest.getOptionValue(value)
		}
		return (item) => item.value
	}

	const onChange = (option) => {
		if (fetchOptions) {
			const tempOption = isMulti ? option : [option]
			setAsyncOptions([...asyncOptions, ...tempOption])
		}
		form.setFieldValue(field.name, isMulti ? option.map((option) => getOptionValue(option)) : getOptionValue(option))
	}

	// This is where we use `valueKey` to pull the values we want to compare out of the options.
	// If you don't pass valueKey in it uses the getOptionValue you passed in to
	// to get the value you need.
	const getOptionValueWithKey = (value) => {
		return valueKey ? value[valueKey] : getOptionValue(value)
	}

	const findMatchingOptions = (value) => {
		const valueFromKey = valueKey ? value[valueKey] : value
		const optionsList = fetchOptions ? asyncOptions : options
		return optionsList.find((option) => getOptionValueWithKey(option) === valueFromKey)
	}
	// This gets the selected options to display in the input. If isMulti is true it
	// needs to loop over all the values and return the matching options otherwise
	// it just finds the matching option for the single value
	const getValue = () => {
		// if (fetchOptions) return field.values
		if (!options && !fetchOptions) return isMulti ? [] : ''

		if (isMulti) {
			// mapped over values instead of using filter on options so not as many loops happen
			return field.value.map(findMatchingOptions)
		}
		if (!field.value) return ''
		return findMatchingOptions(field.value)
	}

	// This is so fetch options doesnt run before any typing. It needs to return a async function so
	// i just returned an empty array from one
	const blank = async () => []
	const promiseOptions = (inputValue) => {
		if (!inputValue) {
			return blank()
		}

		return fetchOptions(inputValue, 10)
	}
	const addedStyles = rest?.customStyles ? rest.customStyles : {}
	const selecProps = {
		name: field.name,
		value: getValue(),
		onChange,
		options,
		isMulti,
		styles: { ...customStyles, ...addedStyles },
		...rest
	}

	if (fetchOptions) {
		return (
			<AsyncSelect
				isMulti
				cacheOptions
				defaultOptions
				loadOptions={promiseOptions}
				noOptionsMessage={() => 'Start Typing to get options'}
				{...selecProps}
			/>
		)
	}

	return <Select {...selecProps} />
}

export default FilterSelect
