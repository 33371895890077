export default [
	{
		abbreviation: 'AST',
		name: 'ATLANTIC STANDARD TIME',
		offset: 'UTC - 4'
	},
	{
		abbreviation: 'EST',
		name: 'EASTERN STANDARD TIME',
		offset: 'UTC - 5'
	},
	{
		abbreviation: 'EDT',
		name: 'EASTERN DAYLIGHT TIME',
		offset: 'UTC - 4'
	},
	{
		abbreviation: 'CST',
		name: 'CENTRAL STANDARD TIME',
		offset: 'UTC - 6'
	},
	{
		abbreviation: 'CDT',
		name: 'CENTRAL DAYLIGHT TIME',
		offset: 'UTC - 5'
	},
	{
		abbreviation: 'MST',
		name: 'MOUNTAIN STANDARD TIME',
		offset: 'UTC - 7'
	},
	{
		abbreviation: 'MDT',
		name: 'MOUNTAIN DAYLIGHT TIME',
		offset: 'UTC - 6'
	},
	{
		abbreviation: 'PST',
		name: 'PACIFIC STANDARD TIME',
		offset: 'UTC - 8'
	},
	{
		abbreviation: 'PDT',
		name: 'PACIFIC DAYLIGHT TIME',
		offset: 'UTC - 7'
	},
	{
		abbreviation: 'AKST',
		name: 'ALASKA TIME',
		offset: 'UTC - 9'
	},
	{
		abbreviation: 'AKDT',
		name: 'ALASKA DAYLIGHT TIME',
		offset: 'UTC - 8'
	},
	{
		abbreviation: 'HST',
		name: 'HAWAII STANDARD TIME',
		offset: 'UTC - 10'
	},
	{
		abbreviation: 'HAST',
		name: 'HAWAII-ALEUTIAN STANDARD TIME',
		offset: 'UTC - 10'
	},
	{
		abbreviation: 'HADT',
		name: 'HAWAII-ALEUTIAN DAYLIGHT TIME',
		offset: 'UTC - 9'
	},
	{
		abbreviation: 'SST',
		name: 'SAMOA STANDARD TIME',
		offset: 'UTC - 11'
	},
	{
		abbreviation: 'SDT',
		name: 'SAMOA DAYLIGHT TIME',
		offset: 'UTC - 10'
	},
	{
		abbreviation: 'CHST',
		name: 'CHAMORRO STANDARD TIME',
		offset: 'UTC +10'
	}
]
